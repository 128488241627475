import React from "react";
import Header from "../components/Header";
import ProjectLists from "../components/ProjectLists";
import { Box, Flex } from "@chakra-ui/react";

const Dashboard = () => {
  return (
    <Box>
      <Header />
      <Flex maxW="1290px" mx="auto" w="80vw" justify="center">
        <ProjectLists />
      </Flex>
    </Box>
  );
};

export default Dashboard;

/* eslint-disable no-undef */
import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Avatar,
  AvatarBadge, Box, Flex, IconButton, Image, Menu,
  MenuButton, MenuDivider, MenuItem, MenuList, useColorModeValue, useDisclosure, useToast
} from "@chakra-ui/react";
import axios from "axios";
import React from "react";
import { BsBriefcaseFill, BsFillPersonFill, BsKey } from "react-icons/bs";
import { GoSignOut } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { AuthContext } from '../contexts/AuthContext';
import MenuDrawer from "./MenuDrawer";
import ModalPasswordChange from "./modal/ModalPasswordChange";
import { useTranslation } from "react-i18next";

const property = {
  imageUrl:
    "https://upload.wikimedia.org/wikipedia/commons/3/33/%E0%B9%81%E0%B8%AA%E0%B8%99%E0%B8%AA%E0%B8%B4%E0%B8%A3%E0%B8%B4-%E0%B8%88%E0%B8%B3%E0%B8%81%E0%B8%B1%E0%B8%94-%E0%B8%A1%E0%B8%AB%E0%B8%B2%E0%B8%8A%E0%B8%99-logo-en.jpg",
  imageAlt: "AheadAll",
  imageUrl2:
    "https://onlinebooking.sansiri.com/stocks/project/d850x550/tw/6l/fuqctw6lp8/master-plan-02.jpg",
  imageAlt2: "AheadAll",
};

const Header = () => {
  const toast = useToast();
  const {isOpen, onOpen ,onClose} = useDisclosure();
  const navigate = useNavigate()
  const bg = useColorModeValue("white", "gray.800");
  const color = useColorModeValue("gray.600", "white");
  const auth = React.useContext(AuthContext);
  const { t } = useTranslation();

  async function handleLogout() {
    try{
      //console.log(auth.user);
      await axios.post(process.env.REACT_APP_BASE_URL + '/logout/', auth.user ,{withCredentials: true});
      localStorage.removeItem("authContext");
      navigate('/Login',{state:null});
    }
    catch(err){
      console.log(err);
    }
  }


  React.useEffect(() => {
    if(!auth.user.username) return;
  },[auth.user]);
  
  async function handleSubmit(values,actions) {
    try {
      const newPassword = { password:values.password };     
      const updateResponse = await axios.put(process.env.REACT_APP_BASE_URL + "/users/" + auth.user.username + "/resetpassword", newPassword,{withCredentials: true});
      if(updateResponse.status == 200){
        toast({ position: 'top-center', title: t("change_password_success"), status: 'success', duration: 5000, isClosable: true,});
        actions.setSubmitting(false);
      }
    }
    catch (err){
      console.log(err);
      actions.setSubmitting(false);
      if(err.response){
        if(err.response?.status == 401){
          redirectToLogin();
        }
        else{
          toast({ position: 'top-center', title: t("change_password_failed"), description:err.response.data.message, status: 'error', duration: 5000, isClosable: true,});
        }
      }
    }
    onClose();
  }

  return (
    <>
    <ModalPasswordChange isOpen={isOpen} onClose={onClose} onSubmit={(values,actions) =>handleSubmit(values,actions)}/>
    <Flex
      pl="5"
      pr="5"
      h="4em"
      justify="space-between"
      alignItems="center"
      boxShadow="md"
      //position="sticky"
    >
      <MenuDrawer bg={bg} color={color} />

      {auth.project?.logo && <Image h="50px" src={`data:image/png;base64,${auth.project?.logo}`} alt={property.imageAlt} />}
      <Box display="flex" alignItems="center">
        <Avatar size="sm" mr="1" name={auth.user.name}>
          <AvatarBadge boxSize="1.25em" bg="green.500" />
        </Avatar>

        <Menu>
          <MenuButton
             icon={<ChevronDownIcon />}
            bg={bg}
            color={color}
            size="md"
            as={IconButton}
          ></MenuButton>
          <MenuList>
            <MenuItem icon={<BsFillPersonFill />}>
              {auth.user.name}
            </MenuItem>
            <MenuItem icon={<BsBriefcaseFill />}>{t("role")} {auth.user.role}</MenuItem>
            <MenuItem onClick={() => onOpen()} icon={<BsKey />}>{t("reset_password")}</MenuItem>
            <MenuDivider />

            <MenuItem icon={<GoSignOut />} onClick={() => handleLogout()} >{t("logout")}</MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </Flex>
    </>
  );
};

export default Header;

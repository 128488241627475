import { Box, Menu, MenuButton, MenuList, MenuItem, IconButton } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = ({ changeLanguage }) => {
  const { i18n } = useTranslation();

  const languages = [
    { code: "en", label: "English", icon: "/flags/united-kingdom.png" },
    { code: "th", label: "ไทย", icon: "/flags/thailand.png" },
  ];

  const currentLanguage = languages.find((lang) => lang.code === i18n.language) || languages[0];

  const handleLanguageChange = (lang) => {
    changeLanguage(lang);
  };

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        icon={<Box as="img" src={currentLanguage.icon} alt={currentLanguage.label} w="30px" h="30px" />}
        variant="ghost"
      />
      <MenuList>
        {languages.map((lang) => (
          <MenuItem
            key={lang.code}
            icon={<Box as="img" src={lang.icon} alt={lang.label} w="30px" h="30px" />}
            onClick={() => handleLanguageChange(lang.code)}
          >
            {lang.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default LanguageSwitcher;

import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  HamburgerIcon,
  EditIcon,
  CalendarIcon,
  PlusSquareIcon,
  BellIcon,
} from "@chakra-ui/icons";
import { FaElevator } from "react-icons/fa6";
import ChangColorMode from "./ChangeColorMode";
import { MdOutlineAddLocation } from "react-icons/md";
import { RxActivityLog } from "react-icons/rx";
import { BiHistory } from "react-icons/bi";
import { FaUserCircle } from "react-icons/fa";
import { IoHardwareChipSharp } from "react-icons/io5";
import {
  Box,
  Text,
  Button,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Switch,
  Stack,
  VStack,
  StackDivider,
  useColorModeValue,
  Icon,
} from "@chakra-ui/react";
import { AuthContext } from '../contexts/AuthContext';
import { useTranslation } from "react-i18next";
import { changeLanguage } from '../components/Translator';
import i18n from '../i18n';
import LanguageSwitcher from "./LanguageSwitcher";

function MenuDrawer() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  let navigate = useNavigate();
  let location = useLocation();
  const bg = useColorModeValue("white", "gray.700");
  const bg2 = useColorModeValue("white", "gray.800");
  const color = useColorModeValue("gray.800", "white");
  const auth = React.useContext(AuthContext);
  const { t } = useTranslation();

  return (
    <>
      {/* Menu button Hamburg style */}
      <IconButton
        ref={btnRef}
        onClick={onOpen}
        bg={bg2}
        color={color}
        aria-label="Menu"
        icon={<HamburgerIcon w={6} h={6} color={color} />}
      />

      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{t("menu")}</DrawerHeader>

          <DrawerBody>
            <VStack
              divider={<StackDivider borderColor="gray.200" />}
              spacing={3}
              m={0}
              align="stretch"
            >
              {auth.user?.role == 'user_management' && <Box display="flex" flexdir="row" h="50px">
                <Button
                  leftIcon={<EditIcon />}
                  bg={bg}
                  color={color}
                  variant="solid"
                  w="100%"
                  h="100%"
                  borderRadius="0px"
                  display="flex"
                  justifyContent="flex-start"
                  onClick={() => {
                    navigate("/dashboard" + location.search);
                  }}
                >
                  {t("project")}
                </Button>
              </Box>}
              {auth.user?.role == 'user_management' && <Box display="flex" flexdir="row" h="50px">
                <Button
                  leftIcon={<PlusSquareIcon />}
                  bg={bg}
                  color={color}
                  variant="solid"
                  w="100%"
                  h="100%"
                  borderRadius="0px"
                  display="flex"
                  justifyContent="flex-start"
                  onClick={() => {
                    navigate("/createproject" + location.search);
                  }}
                >
                  {t("create_project")}
                </Button>
              </Box>}
              {auth.user?.role == 'admin' && <Box display="flex" flexdir="row" h="50px">
                <Button
                  leftIcon={<Icon as={MdOutlineAddLocation} />}
                  bg={bg}
                  color={color}
                  variant="solid"
                  w="100%"
                  h="100%"
                  borderRadius="0px"
                  display="flex"
                  justifyContent="flex-start"
                  onClick={() => {
                    navigate("/selectposition" + location.search);
                  }}
                >
                  {t("add_point")}
                </Button>
              </Box>}
              {auth.user?.role == 'admin' && <Box display="flex" flexdir="row" h="50px">
                <Button
                  leftIcon={<CalendarIcon />}
                  bg={bg}
                  color={color}
                  variant="solid"
                  w="100%"
                  h="100%"
                  borderRadius="0px"
                  display="flex"
                  justifyContent="flex-start"
                  onClick={() => {
                    navigate("/accountmanagement" + location.search);
                  }}
                >
                  {t("manage_users")}
                </Button>
              </Box>}
              {(auth.user?.role == 'niti' || auth.user?.role == 'security') && <Box display="flex" flexdir="row" h="50px">
                <Button
                  leftIcon={<BellIcon />}
                  bg={bg}
                  color={color}
                  variant="solid"
                  w="100%"
                  h="100%"
                  borderRadius="0px"
                  display="flex"
                  justifyContent="flex-start"
                  onClick={() => {
                    navigate("/alarmdetectionboard" + location.search);
                  }}
                >
                  {t("alarm_detection_online")}
                </Button>
              </Box>}
              {(auth.user?.role == 'niti' || auth.user?.role == 'security') && <Box display="flex" flexdir="row" h="50px">
                <Button
                  leftIcon={<Icon as={BiHistory} />}
                  bg={bg}
                  color={color}
                  variant="solid"
                  w="100%"
                  h="100%"
                  borderRadius="0px"
                  display="flex"
                  justifyContent="flex-start"
                  onClick={() => {
                    navigate("/alarmrecord" + location.search);
                  }}
                >
                  {t("save_noti_history")}
                </Button>
              </Box>}
              {(auth.user?.role == 'niti' || auth.user?.role == 'security') && <Box display="flex" flexdir="row" h="50px">
                <Button
                  leftIcon={<Icon as={BiHistory} />}
                  bg={bg}
                  color={color}
                  variant="solid"
                  w="100%"
                  h="100%"
                  borderRadius="0px"
                  display="flex"
                  justifyContent="flex-start"
                  onClick={() => {
                    navigate("/alarmhistory" + location.search);
                  }}
                >
                  {t("noti_history")}
                </Button>
              </Box>}
              {(auth.user?.role == 'niti' || auth.user?.role == 'security') && <Box display="flex" flexdir="row" h="50px">
                <Button
                  leftIcon={<Icon as={FaUserCircle} />}
                  bg={bg}
                  color={color}
                  variant="solid"
                  w="100%"
                  h="100%"
                  borderRadius="0px"
                  display="flex"
                  justifyContent="flex-start"
                  onClick={() => {
                    navigate("/nitiusermanagement" + location.search);
                  }}
                >
                  {auth.user.role == 'niti' ? t("niti_user_management") : t("view_niti_info")}
                </Button>
              </Box>}


              {auth.user?.role == 'user_management' && <Box display="flex" flexdir="row" h="50px">
                <Button
                  leftIcon={<Icon as={BiHistory} />}
                  bg={bg}
                  color={color}
                  variant="solid"
                  w="100%"
                  h="100%"
                  borderRadius="0px"
                  display="flex"
                  justifyContent="flex-start"
                  onClick={() => {
                    navigate("/hardwaremanagement" + location.search);
                  }}
                >
                  {t("hardware_history")}
                </Button>
              </Box>}
              {auth.user?.role == 'user_management' && <Box display="flex" flexdir="row" h="50px">
                <Button
                  leftIcon={<FaUserCircle />}
                  bg={bg}
                  color={color}
                  variant="solid"
                  w="100%"
                  h="100%"
                  borderRadius="0px"
                  display="flex"
                  justifyContent="flex-start"
                  onClick={() => {
                    navigate("/usermanagement" + location.search);
                  }}
                >
                  {t("niti_user_management")}
                </Button>
              </Box>}
              {auth.user?.role == 'user_management' && <Box display="flex" flexdir="row" h="50px">
                <Button
                  leftIcon={<FaUserCircle />}
                  bg={bg}
                  color={color}
                  variant="solid"
                  w="100%"
                  h="100%"
                  borderRadius="0px"
                  display="flex"
                  justifyContent="flex-start"
                  onClick={() => {
                    navigate("/projectmanagement" + location.search);
                  }}
                >
                  {t("manege_project")}
                </Button>
              </Box>}
              {(auth.user?.role == 'niti' || auth.user?.role == 'security' || auth.user?.role == 'admin' || auth.user?.role == 'user_management') && <Box display="flex" flexdir="row" h="50px">
                <Button
                  leftIcon={<IoHardwareChipSharp />}
                  bg={bg}
                  color={color}
                  variant="solid"
                  w="100%"
                  h="100%"
                  borderRadius="0px"
                  display="flex"
                  justifyContent="flex-start"
                  onClick={() => {
                    navigate("/hardwarestatus" + location.search);
                  }}
                >
                  {t("hardware_status")}
                </Button>
              </Box>}
              {auth.user?.role == 'user_management' && <Box display="flex" flexdir="row" h="50px">
                <Button
                  leftIcon={<RxActivityLog />}
                  bg={bg}
                  color={color}
                  variant="solid"
                  w="100%"
                  h="100%"
                  borderRadius="0px"
                  display="flex"
                  justifyContent="flex-start"
                  onClick={() => {
                    navigate("/log" + location.search);
                  }}
                >
                  {t("activities_log")}
                </Button>
              </Box>}
              {auth.user?.role == 'lift' && <Box display="flex" flexdir="row" h="50px">
                <Button
                  leftIcon={<FaElevator />}
                  bg={bg}
                  color={color}
                  variant="solid"
                  w="100%"
                  h="100%"
                  borderRadius="0px"
                  display="flex"
                  justifyContent="flex-start"
                  onClick={() => {
                    navigate("/liftmonitor" + location.search);
                  }}
                >
                  {t("lift")}
                </Button>
              </Box>}
            </VStack>
          </DrawerBody>

          <DrawerFooter justifyContent="flex-start">
            <Stack align="center" direction="row" padding="0rem 0.5rem">
              <LanguageSwitcher changeLanguage={changeLanguage} />
              <ChangColorMode />
            </Stack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default MenuDrawer;

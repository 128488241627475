import { Box, Tooltip } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const offsetX = -25;
const offsetY = -25;

export const Alarm = ({ alarm, width, height }) => {
  const [className, setClassName] = useState("alarm_intruder");
  const { t } = useTranslation();

  useEffect(() => {
    setClassName(getClassIcon(alarm.alarm_type.identifier));
  }, [alarm]);

  function getClassIcon(identifier) {
    const fireIcon = process.env.REACT_APP_ALARM_FIRE_ICON || "alarm_fire";
    const emergencyIcon = process.env.REACT_APP_ALARM_EMERGENCY_ICON || "alarm_emergency";
    const intruderIcon = process.env.REACT_APP_ALARM_INTRUDER_ICON || "alarm_intruder";

    switch (identifier) {
      case 2:
        return fireIcon;
      case 3:
        return emergencyIcon;
      default:
        return intruderIcon;
    }
  }

  return (
    <Tooltip 
      hasArrow 
      label={`${t("house_no")} ${alarm.address} ${alarm.alarm_type.name}`}
    >
      <Box
        className={className}
        w="50px"
        h="50px"
        position="absolute"
        top={`${(Number(alarm.x) * height) / 100 + offsetX}px`}
        left={`${(Number(alarm.y) * width) / 100 + offsetY}px`}
      ></Box>
    </Tooltip>
  );
};

export default Alarm;

/* eslint-disable react/prop-types */
/* eslint-disable no-useless-catch */
/* eslint-disable no-undef */
import {
  Badge,
  Box, Button, chakra, Container, Divider, Flex, FormLabel, Heading, Image, Input, Modal, ModalBody,
  ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Text, useDisclosure, useToast
} from "@chakra-ui/react";
import axios from "axios";
import React from "react";
import { FaTrashAlt } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import Header from "../components/Header";
import { Marker } from '../components/marker/Marker';
import Navigatebar from "../components/Navigatebar";
import SelectProjectBar from "../components/SelectProjectBar";
import { AuthContext } from "../contexts/AuthContext";
import placeholder from '../images/placeholder-project.png';
import ModalBase from '../components/modal/ModalBase';
import { Formik, Form, Field } from 'formik';
import { FieldikPassword, Fieldik } from '../components/form/Fieldik'
import * as validator from "../components/validator/validator";
import { useTranslation } from "react-i18next";

const SelectPosition = () => {
  const fileInput = React.useRef(null);
  const auth = React.useContext(AuthContext);
  const toast = useToast();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isSelectProject, setIsSelectProject] = React.useState(false);
  const [projects, setProjects] = React.useState([]);
  const [selectProject, setSelectProject] = React.useState([]);
  const [_id, set_id] = React.useState("");
  const [x, setX] = React.useState(0);
  const [y, setY] = React.useState(0);
  const [width, setWidth] = React.useState("");
  const [height, setHeight] = React.useState("");

  const [selectMarker, setSelectMarker] = React.useState({});

  const [markers, setMarkers] = React.useState([]);

  const [precisionMode, setPrecisionMode] = React.useState(false);
  const [filename, setFileName] = React.useState(t("upload_date_table"));
  const [validateFile, setValidateFile] = React.useState(false);
  const [jsonMacaddress, setJsonMacaddress] = React.useState([]);

  const projectImage = React.useRef(null);
  const {
    isOpen: isCreateOpen,
    onOpen: onCreateOpen,
    onClose: onCreateClose,
  } = useDisclosure();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();

  const {
    isOpen: isConfirmDeleteOpen,
    onOpen: onConfirmDeleteOpen,
    onClose: onConfirmDeleteClose,
  } = useDisclosure();

  const handleResize = () => {
    setImageWidthAndHight();
  };

  const {
    validateMacaddress,
    validateSerialBoard,
    validateAddress,
    validateName
  } = validator.useValidation();

  async function fetchMakersAsync() {
    if (!_id) return [];
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + "/markers/" + _id, { withCredentials: true }
      );
      return response.data;
    }
    catch (err) {
      throw err;
    }
  }

  React.useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  React.useEffect(() => {
    const fetchProjects = async () => {
      try {
        const res = await axios.get(process.env.REACT_APP_BASE_URL + '/projects', { withCredentials: true });
        setProjects(res.data);
      }
      catch (err) {
        if (err.response) {
          if (err.response?.status == 401) {
            redirectToLogin();
          }
          else {
            toast({ position: 'top-center', title: t("error"), description: err.response.data.message, status: 'error', duration: 5000, isClosable: true, });
          }
        }
        console.log(err);
      }
    }
    fetchProjects();
  }, []);

  React.useEffect(() => {
    const fetchMarker = async () => {
      try {
        const markers = await fetchMakersAsync();
        setMarkers(markers);
      }
      catch (err) {
        if (err.response?.status == 401) {
          redirectToLogin();
        }
        console.log(err);
      }
    }
    fetchMarker();
  }, [selectProject])

  React.useEffect(() => {
    if (_id) {
      setSelectProject(projects.find(p => {
        return p._id == _id;
      }));
    }
  }, [_id]);

  React.useEffect(() => {
    if (jsonMacaddress.length > 0 && jsonMacaddress[0].macaddress) {
      let valid = true;
      jsonMacaddress.forEach(row => {
        if (row.macaddress.length != 12) {
          setFileName(t("upload_date_table"));
          toast({ position: 'top-center', title: t("mac_address_not_12_digits"), description: `บรรทัดที่ ${row.__rowNum__} ${row.macaddress} (${row.macaddress.length} ตัว)`, status: 'error', duration: 5000, isClosable: true, });
          if (fileInput.current) fileInput.current.value = "";
          valid = false;
          return;
        }
      });
      setValidateFile(valid);
    }
    else {
      setValidateFile(false);
      if (fileInput.current) fileInput.current.value = "";
      if (jsonMacaddress.length > 0) {
        toast({ position: 'top-center', title: t("mac_address_notfound"), status: 'error', duration: 5000, isClosable: true, });
        setFileName(t("upload_date_table"));
      }

    }
  }, [jsonMacaddress])

  async function uploadFileHandle(e) {
    try {
      if (e.currentTarget.files.length > 0) {
        setFileName(e.currentTarget.files[0].name);
        const arrayBuffer = await e.currentTarget.files[0].arrayBuffer();
        const wb = XLSX.read(arrayBuffer, { type: 'buffer' })
        const wsname = wb.SheetNames[0]
        const ws = wb.Sheets[wsname]
        const json = XLSX.utils.sheet_to_json(ws)
        setJsonMacaddress(json);
      }
      else {
        setFileName(t("upload_date_table"));
        setJsonMacaddress([]);
      }
    }
    catch (err) {
      console.error(err);
    }
  }

  function redirectToLogin() {
    console.log('redirect to login state /selectposition/');
    if (auth.user.role == 'admin') {
      navigate('/Login', { state: { path: "/selectposition/" } });
    }
    else {
      navigate('/rolehome');
    }
  }

  const setImageWidthAndHight = () => {
    if (projectImage.current?.offsetHeight && projectImage.current?.offsetWidth) {
      setWidth(projectImage.current.offsetWidth);
      setHeight(projectImage.current.offsetHeight);
    }
  };

  const handleSelectProject = (e) => {
    if (e.target.value == "") {
      setIsSelectProject(false);
    } else {
      set_id(e.target.value);
      setIsSelectProject(true);
    }
  };

  const handleImageClick = (e) => {
    const width = projectImage.current.offsetWidth;
    const height = projectImage.current.offsetHeight;
    setWidth(width);
    setHeight(height);

    //set X, Y
    const oY = (e.nativeEvent.offsetX / width) * 100; //top left (X:0 , Y:0)
    const oX = (e.nativeEvent.offsetY / height) * 100; //bottom Right (X:100 , Y:100)
    setX(oX);
    setY(oY);
    onCreateOpen();
  };

  async function handleCreateSubmit(values, actions) {
    const newMarker = {
      macaddress: values.macaddress,
      address: values.address,
      serial_board: values.serial_board,
      aheadallplusId: values.aheadallplusId,
      x: x,
      y: y,
    };

    try {
      const createdNewResponse = await axios.post(process.env.REACT_APP_BASE_URL + "/markers/" + _id, newMarker, { withCredentials: true });
      if (createdNewResponse.status == 200) {
        toast({ position: 'top-center', title: t("add_data_success"), status: 'success', duration: 5000, isClosable: true, });
        const response = await axios.get(process.env.REACT_APP_BASE_URL + "/markers/" + _id, { withCredentials: true });
        setMarkers(response.data);
        actions.setSubmitting(false);
        onCreateClose();
      }
    }
    catch (err) {
      console.log(err);
      if (!err.response) return;
      if (err.response?.status == 401) {
        redirectToLogin();
        return;
      }
      toast({ position: 'top-center', title: t("add_data_failed"), description: err.response.data.message, status: 'error', duration: 5000, isClosable: true, });
    }
    onCreateClose();
  }

  const handleSelectMarker = (marker) => {
    setSelectMarker(marker);
    onEditOpen();
  };

  async function handleDelete() {
    onEditClose();
    setTimeout(() => {
      onConfirmDeleteOpen();
    }, 100)
  }



  function handleCancelConfirmDelete() {
    onConfirmDeleteClose();
    setTimeout(() => {
      onEditOpen();
    }, 100)
  }

  async function handleConfirmDelete() {
    try {
      const response = await axios.delete(process.env.REACT_APP_BASE_URL + "/markers/" + selectMarker._id, { withCredentials: true });
      if (response.status == 200) {
        toast({ position: 'top-center', title: t("delete_data_success"), status: 'success', duration: 5000, isClosable: true, });
        const markers = await fetchMakersAsync();
        setMarkers(markers);
      }
    }
    catch (err) {
      console.log(err);
      if (err.response?.status == 401) {
        redirectToLogin();
      }
      else {
        toast({ position: 'top-center', title: t("delete_data_failed"), status: 'error', duration: 5000, isClosable: true, });
      }
    }
    onConfirmDeleteClose();
  }

  async function handleEditSubmit(values, actions) {
    const editMarker = {
      macaddress: values.macaddress,
      address: values.address,
      serial_board: values.serial_board,
      aheadallplusId: values.aheadallplusId,
    };
    try {
      const updateResponse = await axios.put(process.env.REACT_APP_BASE_URL + "/markers/" + selectMarker._id, editMarker, { withCredentials: true });
      if (updateResponse.status == 200) {
        toast({ position: 'top-center', title: t("edit_data_success"), status: 'success', duration: 5000, isClosable: true, });
        const response = await axios.get(process.env.REACT_APP_BASE_URL + "/markers/" + _id, { withCredentials: true });
        setMarkers(response.data);
        actions.setSubmitting(false);
        onEditClose();
      }
    }

    catch (err) {
      console.log(err);
      if (!err.response) return;
      if (err.response?.status == 401) {
        redirectToLogin();
        return;
      }
      toast({ position: 'top-center', title: t("edit_data_failed"), description: err.response.data.message, status: 'error', duration: 5000, isClosable: true, });
    }
  }


  return (
    <>
      <Header />
      <Navigatebar page={t("add_point")} />
      <Flex align="center" justifyContent="center">
        <SelectProjectBar projects={projects} onSelect={handleSelectProject} />
        <Button colorScheme={precisionMode ? "red" : "teal"} mx={3} onClick={() => {
          setPrecisionMode(!precisionMode);
          setImageWidthAndHight();
        }}>
          {precisionMode ? t("close_dot_mode") : t("open_dot_mode")}
        </Button>
      </Flex>


      {isSelectProject ? (

        <Container maxW="1200px" centerContent>
          {/* <Input ref={fileInput} type="file" onChange={uploadFileHandle} accept=".xlsx"></Input> */}
          <Flex align="center" flexDir="column">
            <label style={{ height: "auto", width: "auto", padding: "6px 10px" }} className="button" htmlFor="upload-markers">{filename}</label>
            <Input h={0} opacity="0" id="upload-markers" type="file" accept=".xlsx" onChange={uploadFileHandle}></Input>
          </Flex>
          <Heading fontSize="36px" mt="12px">{t("add_point_house")}</Heading>
          <Text fontSize="16" w="50%" mt={3} textAlign="center">
            {t("upload_data_table_text")}
          </Text>
          <Box align="center" position="relative" boxShadow="md">
            {selectProject.image ?
              <Image
                ref={projectImage}
                onClick={(e) => handleImageClick(e)}
                onLoad={setImageWidthAndHight}
                src={`data:image/png;base64,${selectProject.image}`}
              /> :
              <Image
                ref={projectImage}
                onClick={(e) => handleImageClick(e)}
                onLoad={setImageWidthAndHight}
                src={placeholder} />
            }
            {markers.map((marker) => {
              return (
                <Box
                  key={marker._id}
                  bg="blue"
                  onClick={() => handleSelectMarker(marker)}

                >
                  <Marker marker={marker} width={width} height={height} precision={precisionMode} />
                </Box>
              );
            })}
          </Box>
        </Container>
      ) : (
        <Container maxW="1200px" centerContent>
          <Heading fontSize="36px">{t("select_project")}</Heading>
          <Text fontSize="16" w="50%" mt={3}>
            {t("add_and_edit")}
          </Text>
        </Container>
      )}

      {/* Edit */}
      <ModalBase onClose={onCreateClose} isOpen={isCreateOpen} isCentered={true}
        header={
          <>
            <chakra.span fontSize={24} color="teal">{t("add")}</chakra.span>
            <chakra.span fontSize={24}> {t("location_info")}</chakra.span>
          </>}
      >
        {<Formik initialValues={{ macaddress: '', serial_board: '', address: '' }} onSubmit={handleCreateSubmit}>
          {(props) => (
            <Form>
              <Container maxW="2xl">
                <Flex align="center">
                  <Heading mr={5} fontSize="18px">
                    {t("hardware_location")}
                  </Heading>
                  <Text mr={3}>X:{x.toFixed(2)}</Text>
                  <Text>Y:{y.toFixed(2)}</Text>
                </Flex>
                <Flex maxHeight="250px" overflowY="auto" justify="space-around" flexDir="column" mt={5} mb={5}>
                  <Box w="100%">
                    <Fieldik name="macaddress" validate={validateMacaddress} type="input" label={t("mac_address_more")} variant="flushed" placeholder={t("please_input_mac_address")} helper={t("ex_mac_address")}></Fieldik>
                  </Box>
                  <Box w="100%">
                    <Fieldik name="serial_board" validate={validateSerialBoard} type="input" label="Serial board" variant="flushed"></Fieldik>
                  </Box>
                  <Box w="100%">
                    <Fieldik name="address" validate={validateAddress} type="input" label={t("house_no")} variant="flushed" helper={t("ex_address")}></Fieldik>
                  </Box>
                  <Box w="100%">
                    <Fieldik name="aheadallplusId" type="input" label={t("aheadallplusId")} variant="flushed" helper={t("ex_aheadallplusId")}></Fieldik>
                  </Box>
                </Flex>
                <Flex mt={5} justifyContent="end">
                  <Button type='submit' colorScheme='teal' mr={3} isLoading={props.isSubmitting} disabled={!(props.isValid && props.dirty)}>
                    {t("submit")}
                  </Button>
                  <Button colorScheme="gray" onClick={onCreateClose}>
                    {t("cancel")}
                  </Button>
                </Flex>
              </Container>
            </Form>
          )}
        </Formik>}
      </ModalBase>

      {/* Edit */}
      <ModalBase onClose={onEditClose} isOpen={isEditOpen} isCentered={true}
        header={
          <>
            <chakra.span fontSize={24} color="yellow.500">{t("edit")}</chakra.span>
            <chakra.span fontSize={24}> {t("location_info")}</chakra.span>
          </>}
      >
        {selectProject._id && <Formik initialValues={{ macaddress: selectMarker.macaddress, serial_board: selectMarker.serial_board, address: selectMarker.address, aheadallplusId: selectMarker.aheadallplusId }} onSubmit={handleEditSubmit}>
          {(props) => (
            <Form>
              <Container maxW="2xl">
                <Flex align="center">
                  <Heading mr={5} fontSize="18px">
                    {t("hardware_location")}
                  </Heading>
                  <Text mr={3}>X:{selectMarker.x.toFixed(2)}</Text>
                  <Text>Y:{selectMarker.y.toFixed(2)}</Text>
                </Flex>
                <Flex maxH="250px" overflowY="auto" justify="space-around" flexDir="column" mt={5} mb={5}>
                  <Box w="100%">
                    <Fieldik name="macaddress" validate={validateMacaddress} type="input" label={t("mac_address_more")} variant="flushed" placeholder={t("please_input_mac_address")} helper={t("ex_mac_address")}></Fieldik>
                  </Box>
                  <Box w="100%">
                    <Fieldik name="serial_board" validate={validateSerialBoard} type="input" label="Serial board" variant="flushed"></Fieldik>
                  </Box>
                  <Box w="100%">
                    <Fieldik name="address" validate={validateAddress} type="input" label={t("house_no")} variant="flushed" helper={t("ex_address")}></Fieldik>
                  </Box>
                  <Box w="100%">
                    <Fieldik name="aheadallplusId" type="input" label={t("aheadallplusId")} variant="flushed" helper={t("ex_aheadallplusId")}></Fieldik>
                  </Box>
                </Flex>
                <Flex mt={5} >
                  <Flex w="50%" justifyContent="start">
                    <Button leftIcon={<FaTrashAlt />} colorScheme="red" onClick={handleDelete}>{t("delete")}</Button>
                  </Flex>
                  <Flex w="50%" justifyContent="end">
                    <Button type='submit' colorScheme='teal' mr={3} isLoading={props.isSubmitting} disabled={!(props.isValid && props.dirty)}>
                      {t("submit")}
                    </Button>
                    <Button colorScheme="gray" onClick={onEditClose}>
                    {t("cancel")}
                    </Button>
                  </Flex>
                </Flex>
              </Container>
            </Form>
          )}
        </Formik>}
      </ModalBase>

      <ModalBase onClose={() => handleCancelConfirmDelete()} isOpen={isConfirmDeleteOpen} isCentered={true}
        header={
          <>
            <chakra.span fontSize={24} color="red.500">{t("confirm_delete")}</chakra.span>
            <chakra.span fontSize={24}> {t("location_info")}</chakra.span>
          </>}>
        <Flex flexDirection="column">
          <Flex m={2} alignItems="center">
            <Flex w="50%">
              <Heading verticalAlign="middle" fontSize="16px">Mac Address</Heading>
            </Flex>
            <Flex w="50%">
              <Input value={selectMarker.macaddress} variant="filled" readOnly></Input>
            </Flex>
          </Flex>
          <Flex m={2} alignItems="center">
            <Flex w="50%">
              <Heading verticalAlign="middle" fontSize="16px">Serial board</Heading>
            </Flex>
            <Flex w="50%">
              <Input value={selectMarker.serial_board} variant="filled" readOnly></Input>
            </Flex>
          </Flex>
          <Flex m={2} alignItems="center">
            <Flex w="50%">
              <Heading verticalAlign="middle" fontSize="16px">{t("address")}</Heading>
            </Flex>
            <Flex w="50%">
              <Input value={selectMarker.address} variant="filled" readOnly></Input>
            </Flex>
          </Flex>
        </Flex>
        <Flex mt={5} justifyContent="end">
          <Button colorScheme='red' mr={3} onClick={handleConfirmDelete}>
            {("submit")}
          </Button>
          <Button colorScheme="gray" onClick={() => handleCancelConfirmDelete()}>
          {("cancel")}
          </Button>
        </Flex>
      </ModalBase>
    </>
  );
};

export default SelectPosition;
